import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import dictionary from '../../../utils/dictionary';
import { addZero, timeDiff } from '../../../utils/system';
import { breakpoints, sizing, font } from '../../../utils/style';

const Wrap = styled('div')`
  display: flex;
  align-items: center;
  .text-timer {
    font-size: ${sizing.normal};
  }
  .timer {
    font-size: 20px;
    font-weight: ${font.medium};
    color: #ffd10a;
    margin-left: 10px;
    width: 85px;
  }
  @media (max-width: ${breakpoints.md}) {
    margin-top: 20px;
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}) {
    margin-top: 29px;
    flex-direction: column;
    .timer {
      margin: 12px 0 0 0;
      width: 100%;
    }
  }
`;

const PredictionTimer = ({ lastBetTime }) => {
  const parsedTime = timeDiff(lastBetTime);

  if (!parsedTime) return null;
  if (parsedTime.hours < 0 && parsedTime.minutes < 0) return null;

  const [hours, setHours] = useState(parsedTime.hours);
  const [minutes, setMinutes] = useState(parsedTime.minutes);
  const [seconds, setSeconds] = useState(parsedTime.seconds);

  useEffect(() => {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return () => {
      };
    }

    const interval = setInterval(() => {
      if (seconds === 0) {
        setSeconds(59);

        if (minutes === 0) {
          setMinutes(59);
          setHours((prevState) => prevState - 1);
        } else {
          setMinutes((prevState) => prevState - 1);
        }
      } else {
        setSeconds((prevState) => prevState - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  if (hours === 0 && minutes === 0 && seconds === 0) return null;

  return (
    <Wrap>
      <span className="text-timer">{dictionary.timerText}</span>
      <span className="timer">
        {addZero(hours)}:{addZero(minutes)}:{addZero(seconds)}
      </span>
    </Wrap>
  );
};

export default PredictionTimer;
