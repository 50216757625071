import React, { useContext, useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import dictionary from '../../../../utils/dictionary';
import {
  border, breakpoints, flex, hideScroll, resetButton, sizing, font, color,
} from '../../../../utils/style';
import { timeDiff } from '../../../../utils/system';
import { StoreContext } from '../../../../store';
import {
  toggleLeague,
  setCurrPredictionsLeagueId,
  predictionSetAccepted,
  setProfileBets,
  loadPredictionsRounds,
} from '../../../../store/actions';
import Match from './Match';
import PredictionTimer from '../PredictionTimer';
import PredictionAccepted from '../PredictionAccepted';
import { predictionMakeBet, predictionUserBets } from '../../../../utils/api/prediction';
import FullError from '../../FullError';
import WithError from '../../WithError';
import { useModalOpen } from '../../Modal';
import GetWindowWidth from '../../GetWindowWidth';

const Wrap = styled('div')`
  margin-top: 40px;
  position: relative;
  ${flex};
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    margin: 0;
  }
  & > h2 {
    text-align: center;
  }

  & .bottom-panel {
    width: 100%;
    margin-top: 30px;
    ${flex};
    justify-content: space-between;

    .prediction-available {
      font-size: ${sizing.normal};
      font-weight: ${font.normalWeight};
      .register-btn {
        color: ${color.blue};
        border: none;
        background-color: unset;
        font-size: ${sizing.normal};
        font-family: ${font.default};
        padding: 0;
        cursor: pointer;
        outline: none;
        margin: 0;
        width: unset;
      }
    }

    & .game-history {
      ${resetButton};
      font-family: Kanit;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2da5fe;

      &:hover {
        color: #57b4f6;
      }

      &:active {
        opacity: 0.9;
      }

      &.hide {
        visibility: hidden;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    justify-content: space-around;
  }
  @media (max-width: ${breakpoints.sm}) {
    .bottom-panel {
      &-unregister {
        flex-direction: column;
        align-items: flex-start;
      }
      .prediction-available {
        margin-top: 22px;
      }
      .hide {
        display: none;
      }
    }
  }
`;

const SubmitButton = styled('button')`
  ${resetButton};
  width: 114px;
  height: 44px;
  border-radius: ${border.borderRadius};
  background-color: #fb0250 !important;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fbfcfd;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`;

const MobileScrollContainer = styled('div')`
  ${flex};
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    max-height: calc(62px * 5.5);
    overflow-y: scroll;
    ${hideScroll};
    justify-content: space-around;
  }
`;

const Matches = () => {
  const windowWidth = GetWindowWidth();
  const isDesktop = windowWidth > 960;
  const [state, dispatch] = useContext(StoreContext);
  const {
    predictions: { id, isRoundsLoaded, predictionData, matchBets, currentLeague: { last_bet_time } = {} } = {},
    profile: { betsFormatted } = {},
    user: { userName } = {},
  } = state;

  const [error, setError] = useState('');

  const [timerAfterPrediction, setTimerAfterPrediction] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => setError(error), 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  if (!isRoundsLoaded || !id) return null;

  // Data handlers
  const fromObjToArr = (obj) => (!obj ? [] : Object.keys(obj)
    ?.map((key) => obj[key]));
  const roundsFiltered = predictionData.filter(({ id: leagueId }) => leagueId === id);
  const activeRound = roundsFiltered.length > 0 ? roundsFiltered[0] : {};
  const rounds = fromObjToArr(activeRound.rounds);
  const matchesCount = useCallback(() => Object.keys(matchBets).length, [matchBets]);

  // Components handlers
  const loginOpenHandler = useModalOpen('login-popup-window');
  const toGameHistory = useCallback(() => {
    toggleLeague(dispatch);
    setCurrPredictionsLeagueId(dispatch, '');
    window.location.href = '#home-predictions';
  }, []);

  const sendBets = (e) => {
    if (!userName) {
      loginOpenHandler(e);
      return false;
    }
    if (timeDiff(last_bet_time)) return false;

    predictionMakeBet(id, matchBets)
      .then((json) => {
        if (!json) {
          setError(dictionary.formBagRequest);
          return () => {
          };
        }
        dispatch(true);
        const { success, message } = json;
        if (!success) {
          setError(message);
          return () => {
          };
        }

        predictionSetAccepted(dispatch, true);

        predictionUserBets()
          .then((json) => {
            if (!json) return false;

            const { status, result = [] } = json;
            if (!status) return false;

            setProfileBets(dispatch, result);
          })
          .catch((err) => {
            console.error('Bets request error: ', err);
          });

        setTimerAfterPrediction({
          timeValue: new Date().toISOString()
            .replace('Z', ''),
          league: state.predictions.currentLeague.id,
        });

        loadPredictionsRounds(dispatch);
      })
      .catch((err) => {
        console.error('make prediction request: ', err);
      });
  };

  const checkTimerForCurrentLeague = () => {
    if (timerAfterPrediction) {
      if (timerAfterPrediction.league === activeRound.id) {
        return true;
      }
    }

    return false;
  };

  if (error) return <FullError error={error} />;

  return (
    <Wrap>
      <WithError error={error}>
        <PredictionAccepted />
        {rounds.length === 0 && <h2>{dictionary.noMatchesForBet}</h2>}
        {rounds && (
          <MobileScrollContainer>
            {rounds?.map(({ round_id, matches }) => {
              if (!matches || matches.length === 0) return null;
              return fromObjToArr(matches)
                ?.map((match) => (
                  <Match
                    key={match.match_id}
                    roundId={round_id}
                    match={match}
                    selected={betsFormatted[match.match_id] ? match.match_id : false}
                  />
                ));
            })}
          </MobileScrollContainer>
        )}
        <div className={`bottom-panel ${!userName ? 'bottom-panel-unregister' : ''}`}>
          {activeRound && (
            <SubmitButton onClick={sendBets} disabled={!matchesCount() > 0 || checkTimerForCurrentLeague() || timeDiff(last_bet_time)}>
              {dictionary.makeABet}
            </SubmitButton>
          )}
          {!userName && (
            <div className="prediction-available">
              Predictions are available for registered users only. Please{' '}
              <button type="button" className="register-btn" onClick={loginOpenHandler}>
                login
              </button>
              {' '}
              or{' '}
              <a href="/sign-up-now/" className="register-btn">
                register
              </a>{' '}
              to continue.
            </div>
          )}
          {isDesktop && timerAfterPrediction && state.predictions.currentLeague.id === timerAfterPrediction.league && <PredictionTimer lastBetTime={timerAfterPrediction.timeValue} />}
          {isDesktop && <PredictionTimer lastBetTime={last_bet_time} />}
          <button type="button" className={`game-history ${!userName ? 'hide' : ''}`} onClick={toGameHistory}>
            {dictionary.gameHistory}
          </button>
        </div>

        {!isDesktop && timerAfterPrediction && state.predictions.currentLeague.id === timerAfterPrediction.league && <PredictionTimer lastBetTime={timerAfterPrediction.timeValue} />}
        {!isDesktop && <PredictionTimer lastBetTime={last_bet_time} />}
      </WithError>
    </Wrap>
  );
};

export default Matches;
