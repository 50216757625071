import React, { useState, useContext } from 'react';

import styled from '@emotion/styled';
import { border, breakpoints, flex, resetButton } from '../../../../../utils/style';
import config from './config';

import { StoreContext } from '../../../../../store';
import { setBetOnMatch, removePredictionsTeam } from '../../../../../store/actions';

const Wrap = styled('div')`
  width: max-content;

  ${flex};

  &.error {
    & .box {
      border: solid 1px #e02020;
    }
  }

  & .box {
    ${resetButton};
    width: 44px;
    height: 44px;
    border-radius: ${border.borderRadius};
    background-color: #2b3457;
    margin-left: 6px;
    ${flex};

    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      background-color: rgba(45, 165, 254, 0.8);
    }

    &.active {
      background-color: #2da5fe;
    }

    &.selected {
      background-color: rgba(45, 165, 254, 0.5);
    }

    & > span {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  &.true {
    & .box {
      cursor: default;
      pointer-events: none;

      &.selected {
        &:hover,
        &.active {
          background-color: rgba(45, 165, 254, 0.5);
        }
      }

      &:hover,
      &.active {
        background-color: #2b3457;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & .box {
      width: 35px;
      height: 35px;
    }
  }
`;

const BetPanel = ({ roundId, matchId, selected }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { predictions: { matchBets } = {}, profile: { betsFormatted = {} } = {} } = state;

  const [choosed, setChoosed] = useState(null);

  const chooseHandler = (winMode) => {
    if (winMode === choosed) {
      removePredictionsTeam(dispatch, matchBets, roundId, matchId);
      setChoosed(null);
    } else {
      setBetOnMatch(dispatch, matchBets, roundId, matchId, winMode);
      setChoosed(winMode);
    }
  };

  return (
    <Wrap className={!!selected}>
      <button
        type="button"
        className={`box ${choosed === config.WIN_HOME ? 'active' : ''} ${
          selected && betsFormatted[selected].predict_num === config.WIN_HOME ? 'selected' : ''
        }`}
        onClick={() => chooseHandler(config.WIN_HOME)}
      >
        <span>w1</span>
      </button>
      <button
        type="button"
        className={`box ${choosed === config.DRAW ? 'active' : ''} ${
          selected && betsFormatted[selected].predict_num === config.DRAW ? 'selected' : ''
        }`}
        onClick={() => chooseHandler(config.DRAW)}
      >
        <span>x</span>
      </button>
      <button
        type="button"
        className={`box ${choosed === config.WIN_AWAY ? 'active' : ''} ${
          selected && betsFormatted[selected].predict_num === config.WIN_AWAY ? 'selected' : ''
        }`}
        onClick={() => chooseHandler(config.WIN_AWAY)}
      >
        <span>w2</span>
      </button>
    </Wrap>
  );
};

export default BetPanel;
