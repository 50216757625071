import React from 'react';

const Promotion = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="70" height="66" viewBox="0 0 70 66">
    <defs />
    <defs>
      <filter id="apromotion" width="162%" height="168.3%" x="-31%" y="-34.2%" filterUnits="objectBoundingBox">
        <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0.0521285726 0 0 0 0 0.0732886905 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="5" />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0.563123886 0 0 0 0 0.996078431 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter id="cpromotion" width="151.7%" height="156.9%" x="-25.8%" y="-28.5%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner1" />
        <feGaussianBlur in="shadowSpreadInner1" result="shadowBlurInner1" stdDeviation=".5" />
        <feOffset dy="-1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 0.0496539572 0 0 0 0 0.127964647 0 0 0 0 0.529211957 0 0 0 1 0"
        />
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner2" />
        <feGaussianBlur in="shadowSpreadInner2" result="shadowBlurInner2" stdDeviation="1.5" />
        <feOffset dy="-1" in="shadowBlurInner2" result="shadowOffsetInner2" />
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        />
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0.0470588235 0 0 0 0 0.648738302 0 0 0 0 1 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixInner1" />
          <feMergeNode in="shadowMatrixInner2" />
        </feMerge>
      </filter>
      <filter id="dpromotion" width="131.2%" height="176.9%" x="-15.6%" y="-32.5%" filterUnits="objectBoundingBox">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 0.00784313725 0 0 0 0 0.321568627 0 0 0 1 0" />
      </filter>
      <filter id="fpromotion" width="126.4%" height="165.1%" x="-13.2%" y="-26.6%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner1" />
        <feGaussianBlur in="shadowSpreadInner1" result="shadowBlurInner1" stdDeviation=".5" />
        <feOffset dy="-1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 0.732506793 0 0 0 0 0.0689377106 0 0 0 0 0.283858871 0 0 0 1 0"
        />
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner2" />
        <feGaussianBlur in="shadowSpreadInner2" result="shadowBlurInner2" stdDeviation=".5" />
        <feOffset dy="-1" in="shadowBlurInner2" result="shadowOffsetInner2" />
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        />
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 1 0 0 0 0 0.00392156863 0 0 0 0 0.317647059 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixInner1" />
          <feMergeNode in="shadowMatrixInner2" />
        </feMerge>
      </filter>
      <path
        id="bpromotion"
        d="M4.387 44.2A4.087 4.087 0 01.3 40.113V4.387A4.087 4.087 0 014.387.3h40.228A4.086 4.086 0 0148.7 4.387v35.728a4.086 4.086 0 01-4.087 4.085zM22.8 3.699L4.387 3.7a.687.687 0 00-.687.687v35.726c0 .38.308.687.687.687l18.413-.001V29a1.7 1.7 0 013.394-.147L26.2 29v11.799l18.413.001c.345 0 .63-.254.68-.584l.007-.101V4.387a.686.686 0 00-.685-.687L26.2 3.699V15.5a1.7 1.7 0 01-3.394.147L22.8 15.5V3.699z"
      />
      <path
        id="epromotion"
        d="M8.75 13.8a3.95 3.95 0 013.945 3.747l.005.203v9a3.95 3.95 0 01-3.747 3.945l-.203.005H3.7v-3.4h5.05c.273 0 .5-.2.543-.46l.007-.09v-9c0-.273-.2-.5-.46-.543l-.09-.007H3.7v-3.4h5.05zm36.55 0v3.4h-5.05c-.273 0-.5.2-.543.46l-.007.09v9c0 .273.2.5.46.543l.09.007h5.05v3.4h-5.05a3.95 3.95 0 01-3.945-3.747l-.005-.203v-9a3.95 3.95 0 013.747-3.945l.203-.005h5.05zm-14.825 2.475a8.45 8.45 0 010 11.95 8.45 8.45 0 01-11.95 0 8.45 8.45 0 010-11.95 8.45 8.45 0 0111.95 0zm-9.546 2.404a5.05 5.05 0 107.311.177l-.17-.177-.176-.169a5.05 5.05 0 00-6.965.17z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(10.5 10.75)">
          <use fill="#000" filter="url(#apromotion)" xlinkHref="#bpromotion" />
          <use fill="#8DDEFF" xlinkHref="#bpromotion" />
          <use fill="#000" filter="url(#cpromotion)" xlinkHref="#bpromotion" />
        </g>
        <g transform="translate(10.5 10.75)">
          <use fill="#000" filter="url(#dpromotion)" xlinkHref="#epromotion" />
          <use fill="#FF5C8F" xlinkHref="#epromotion" />
          <use fill="#000" filter="url(#fpromotion)" xlinkHref="#epromotion" />
        </g>
      </g>
      <path d="M8 6h54v54H8z" />
    </g>
  </svg>
);

export default Promotion;
