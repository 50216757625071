import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex } from '../../../../utils/style';

const Wrap = styled.div`
  width: max-content;
  margin-left: 30px;

  ${flex};

  & .team {
    width: 150px;
    height: 100%;

    ${flex};
    justify-content: flex-start;

    & > img {
      width: 20px;
      margin-right: 17px;
      border-radius: 50%;
      border: 1px solid #283361;
    }

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.4;

      font-family: Kanit;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  & .divider {
    margin: 0 50px;

    font-family: Kanit;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2da5fe;
  }

  &.completed {
    & .team {
      & > span {
        opacity: 1;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    position: relative;
    flex-direction: column;
    align-content: space-between;
    padding: 12px 0;
    margin-left: 0;

    & .team {
      width: 100px;
      padding-left: 0;

      & > img {
        width: 20px;
        margin-right: 6px;
      }
    }

    & .divider {
      display: none;
    }
  }
`;

const MobileDivider = styled('div')`
  display: none;

  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 10px 0;
  margin-left: auto;

  & > span {
    font-family: Kanit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #2da5fe;
  }

  @media (max-width: ${breakpoints.md}) {
    display: flex;
  }
`;

const TableTeams = ({ home = {}, away = {}, completed = true, homePredict, awayPredict }) => {
  const { name: homeName, logo: homeLogo } = home;
  const { name: awayName, logo: awayLogo } = away;

  return (
    <>
      <Wrap className={completed ? 'completed' : ''}>
        <div className="team">
          <img alt="Home Logo" src={homeLogo} />
          <span>{homeName}</span>
        </div>
        <div className="divider">
          {completed && (
            <span>
              {homePredict}&nbsp;:&nbsp;{awayPredict}
            </span>
          )}
          {!completed && <span>?&nbsp;:&nbsp;?</span>}
        </div>
        <div className="team">
          <img alt="Away Logo" src={awayLogo} />
          <span>{awayName}</span>
        </div>
      </Wrap>
      <MobileDivider className="divider">
        {completed && (
          <>
            <span>{homePredict}</span>
            <span>{awayPredict}</span>
          </>
        )}
        {!completed && (
          <>
            <span>?</span>
            <span>?</span>
          </>
        )}
      </MobileDivider>
    </>
  );
};

export default TableTeams;
