import React from 'react';

import styled from '@emotion/styled';

import DateTime from './DateTime';
import Teams from './Teams';
import BetPanel from './BetPanel';
import { breakpoints, flex } from '../../../../../utils/style';

const Wrap = styled('div')`
  width: 352px;
  height: 55px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  padding-bottom: 10px;
  ${flex};
  justify-content: flex-start;
  @media (max-width: ${breakpoints.md}) {
    width: calc(288px - 32px);
    height: 62px;
  }
  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
  }
`;

const Match = ({ roundId, match, selected }) => {
  const { match_id, date, time, away, home } = match;

  return (
    <Wrap>
      <DateTime date={date} time={time} />
      <Teams away={away} home={home} />
      <BetPanel roundId={roundId} matchId={match_id} selected={selected} />
    </Wrap>
  );
};

export default Match;
