import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex, customScroll, resetList, textEllipsis } from '../../../../../utils/style';
import { StoreContext } from '../../../../../store';
import { loadPredictionsLeaderBoards as loadLeaderboard } from '../../../../../store/actions';
import dictionary from '../../../../../utils/dictionary';
import WithLoader from '../../../WithLoader';

const Wrap = styled('ul')`
  ${resetList};
  ${customScroll};
  height: 77%;
  overflow-y: auto;
  & > li {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    display: flex;
    align-items: center;

    & .number {
      width: 25px;

      font-family: Kanit;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      opacity: 0.6;
    }

    & .name {
      width: 100px;
      height: 100%;
      margin-left: 24px;

      ${flex};
      justify-content: flex-start;

      font-family: Kanit;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;

      & > span {
        ${textEllipsis};
      }
    }

    & .prediction {
      width: 65px;

      font-family: Kanit;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;

      ${flex};
    }

    & .points {
      width: 25px;
      margin-left: auto;
      margin-right: 10px;

      font-family: Kanit;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffd10a;

      ${flex};
    }
  }

  @media (max-width: ${breakpoints.md}) {
    max-height: calc(55px * 5);
  }
`;

const Headers = styled('div')`
  display: flex;
  height: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  & span {
    opacity: 0.6;
    font-family: Kanit;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  & .user {
    margin-left: 24px;
  }

  & .pred {
    margin-left: 78px;
  }

  & .points {
    margin-left: auto;
    margin-right: 10px;
  }

  & .header {
    &:first-of-type {
      width: 25px;
    }
  }
`;

const List = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { predictions: { isLeadersLoaded = false, leaderData = {} } = {} } = state;

  useEffect(() => {
    if (!isLeadersLoaded) {
      loadLeaderboard(dispatch)(1197);
    }
    return () => {};
  }, []);

  return (
    <>
      <Headers className="headers">
        <div className="header">
          <span>#</span>
        </div>
        <div className="header user">
          <span>{dictionary.user}</span>
        </div>
        <div className="header pred">
          <span>{dictionary.prediction}</span>
        </div>
        <div className="header points">
          <span>{dictionary.points}</span>
        </div>
      </Headers>
      <Wrap>
        <WithLoader isLoading={!isLeadersLoaded}>
          {leaderData?.map(({ username, predictions, points }, i) => (
            <li className="item" key={username}>
              <div className="number">
                <span>{i + 1}</span>
              </div>
              <div className="name">
                <span>{username}</span>
              </div>
              <div className="prediction">
                <span>{predictions}</span>
              </div>
              <div className="points">
                <span>{points}</span>
              </div>
            </li>
          ))}
        </WithLoader>
      </Wrap>
    </>
  );
};

export default List;
