import React, { useCallback, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import IcomoonIcon from '../IcomoonIcon';
import { border, breakpoints, flex, hideScroll, resetList } from '../../../utils/style';

import { StoreContext } from '../../../store';
import {
  loadPredictionsRounds as loadRounds,
  setCurrPredictionsLeagueId,
  toggleLeague,
  predictionSetCurrentLeague,
} from '../../../store/actions';

const Wrap = styled('ul')`
  ${resetList};

  width: 100%;
  margin-top: 30px;
  margin-bottom: -1px;

  ${flex};
  justify-content: flex-start;

  & > li {
    min-width: 63px;
    margin-left: 8px;
    border-radius: ${border.borderRadius} ${border.borderRadius} 0 0;
    background-color: #111731;
    padding: 13px 8px;
    cursor: pointer;
    ${flex};

    &:first-of-type {
      margin-left: 0;
    }

    & > span {
      font-family: Kanit;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #a0a9bd;
      text-transform: uppercase;
    }

    &.active,
    &:hover {
      background-color: #141d44;

      & > span {
        color: #ffffff;
      }

      & > svg,
      & path {
        fill: #ffffff !important;
        opacity: 1 !important;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: -webkit-box;
    overflow: hidden;
    overflow-x: scroll;
    ${hideScroll};
    margin-bottom: 0;
    height: 48px;

    & > li {
      min-width: 44px;
      height: 100%;
      margin-left: 4px;

      & > span {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
`;

const Tabs = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { predictions: { id, isRoundsLoaded, predictionData } = {}, user: { userName } = {} } = state;

  useEffect(() => {
    if (!isRoundsLoaded) {
      loadRounds(dispatch)();
    }
  }, []);
  useEffect(() => {
    if (!isRoundsLoaded && userName) {
      loadRounds(dispatch)();
    }
    if (!isRoundsLoaded) return () => {};
    setCurrPredictionsLeagueId(dispatch, predictionData[0].id);
    predictionSetCurrentLeague(dispatch, predictionData, predictionData[0].id);
  }, [isRoundsLoaded, userName]);

  const leagueSwitcher = useCallback(
    (id) => {
      toggleLeague(dispatch);
      setCurrPredictionsLeagueId(dispatch, id);
      predictionSetCurrentLeague(dispatch, predictionData, id);
    },
    [isRoundsLoaded],
  );

  return (
    <Wrap>
      {userName && (
        <li className={`${!id ? 'active' : ''}`} onClick={() => leagueSwitcher('')}>
          <IcomoonIcon icon="info" color="" className="" size={24} />
        </li>
      )}
      {predictionData?.map((tab) => {
        const { id: leagueId, title } = tab;
        return (
          <li key={leagueId} className={`${leagueId === id ? 'active' : ''}`} onClick={() => leagueSwitcher(leagueId)}>
            <span>{title}</span>
          </li>
        );
      })}
    </Wrap>
  );
};

export default Tabs;
