import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout-prediction';
import SEO from '../../components/SEO/SEO';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { breakpoints, formStyles, button, color, sizing } from '../../utils/style';
import Prediction from '../../components/shared/Prediction/Prediction';
import Promotion from '../../icons/svg/Promotion';

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  p {
    margin-bottom: 1rem;
  }
  .promo-title {
    width: 100%;
    margin-bottom: -100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
      margin: 0;
      font-size: ${sizing.huge};
    }
  }
  .promotion-banner {
    position: sticky;
    margin-bottom: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .promotion-text {
      margin: -44px 0 0 0;
      &:after {
        content: 'เกมทายผล';
        color: ${color.white};
        font-size: 184px;
        opacity: 0.06;
        transform: translate(-456 -266);
        text-transform: uppercase;
      }
    }
    .promotion-background {
      opacity: 0.27;
      -webkit-filter: blur(46px);
      filter: blur(46px);
      background-color: #004cef;
      width: 80%;
      height: 100%;
      position: absolute;
      top: 50%;
      border-radius: 30%;
      pointer-events: none;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .promotion-banner {
      .promotion-text {
        &:after {
          font-size: 150px;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .promo-title {
      margin-bottom: -60px;
    }
    .promotion-banner {
      margin-bottom: -30px;
      .promotion-text {
        &:after {
          font-size: 120px;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.s}) {
    .promotion-banner {
      .promotion-text {
        &:after {
          font-size: 90px;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .breadcrumbs {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding-left: 16px;
    }
    .promo-title {
      padding-top: 40px;
      h3 {
        font-size: 34px;
      }
    }
    .promotion-banner {
      margin-bottom: -80px;
      .promotion-text {
        margin: 0;
        &:after {
          font-size: 60px;
        }
      }
    }
  }
`;

const Content = styled('div')`
  margin: 30px 0 60px;
  a {
    ${button};
    margin: 39px 0;
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }
  @media (max-width: ${breakpoints.sm}) {
    margin-top: 30px;
  }
  @media (max-width: ${breakpoints.xs}) {
    margin-top: 65px;
  }
`;

const PredictionGame = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedIds = pageContext.related;
  const { breadcrumbs } = pageContext;

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentPage.path} breadcrumbs={breadcrumbs} />
      <Wrapper>
        <div className="promo-title">
          <Promotion />
          <h3>{currentPage.title}</h3>
        </div>
        <div className="promotion-banner">
          <h1 className="promotion-text" />
          <div className="promotion-background" />
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentPage.title} />
        {/* {relatedIds.length > 0 && (
          <Content>
            <Prediction />
          </Content>
        )} */}
        <h2>Cooming soon...</h2>
      </Wrapper>
    </Layout>
  );
};

export default PredictionGame;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`;
