import React from 'react';

import styled from '@emotion/styled';
import { border, breakpoints, flex } from '../../../../utils/style';
import config from '../../../shared/Prediction/Matches/Match/config';

const Wrap = styled('div')`
  width: 30px;
  height: 30px;
  border-radius: ${border.borderRadius};
  background-color: rgba(45,165,254,0.5);
  margin-left: auto;
  
  ${flex};
  
  & > span {
    font-family: Kanit;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin-left: 17px;
  }
`;

const WinMode = ({ predictNum }) => (
  <Wrap>
    <span>{config[predictNum]}</span>
  </Wrap>
);

export default WinMode;
