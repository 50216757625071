import React, { useContext } from 'react';

import styled from '@emotion/styled';
import { border, breakpoints } from '../../../utils/style';
import dictionary from '../../../utils/dictionary';

import { StoreContext } from '../../../store';
import TopAlert from './TopAlert';
import Matches from './Matches';
import GameHistory from './GameHistory';

const Wrap = styled('div')`
  width: 100%;
  min-height: 100px;
  border-radius: 0 0 ${border.borderRadius} ${border.borderRadius};
  background-color: #141d44;
  padding: 50px 40px;

  @media (max-width: ${breakpoints.md}) {
    padding: 31px 16px 40px;
  }
`;

const Body = () => {
  const [state] = useContext(StoreContext);
  const {
    predictions: { id },
    user: { userName } = {},
  } = state;

  return (
    <Wrap>
      {/* Help with text for user about bets */}
      {id && <TopAlert text={dictionary.predictionHelpText} />}

      {/* Matches table by active league */}
      {id && <Matches />}
      {userName && (
        <>
          {/* Game History component */}
          {!id && <GameHistory />}
        </>
      )}
    </Wrap>
  );
};

export default Body;
