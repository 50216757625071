import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex, textEllipsis, font } from '../../../../../utils/style';

const Wrap = styled('div')`
  width: 145px;
  height: 100%;
  margin-left: 8px;
  ${flex};
  flex-direction: column;

  & .team {
    width: 100%;
    height: max-content;

    ${flex};
    justify-content: flex-start;

    & > img {
      width: 20px;
      border-radius: 50%;
      border: 1px solid #283361;
    }

    & .name {
      ${textEllipsis};
      & > span {
        font-weight: ${font.medium};
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 92px;

    & .name {
      font-size: 14px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
  }
`;

const Teams = ({ away, home }) => {
  const { logo: logoAway, name: nameAway } = away;
  const { logo: logoHome, name: nameHome } = home;

  return (
    <Wrap>
      <div className="team">
        <img src={logoHome} alt="home-logo" />
        <div className="name">
          <span>{nameHome}</span>
        </div>
      </div>
      <div className="team">
        <img src={logoAway} alt="away-logo" />
        <div className="name">
          <span>{nameAway}</span>
        </div>
      </div>
    </Wrap>
  );
};

export default Teams;
