import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex } from '../../../../utils/style';

const Wrap = styled('div')`
  margin-left: 65px;
  margin-right: 25px;
  width: 15px;
  
  ${flex};

  & > span {
    width: 100%;
    font-family: Kanit;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    
    &.positive {
      color: #ffd10a;
    }
    
    &.negative {
      color: #fb0250;
    }
    
    &.none {
      opacity: 0.4;
      font-family: Kanit;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.8;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin-left: 17px;
    margin-right: 5px;
  }
`;

const Points = ({ points }) => (
  <Wrap>
    {
      typeof points === 'number' || typeof points === 'string'
        ? (
          <>
            {+points > 0 && <span className="positive">+{points}</span>}
            {+points === 0 && <span className="positive">{points}</span>}
            {+points < 0 && <span className="negative">{points}</span>}
          </>
        )
        : (
          <>
            {!points && <span className="none">?</span>}
          </>
        )
    }
  </Wrap>
);

export default Points;
