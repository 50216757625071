import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';
import { border, breakpoints, flex, resetButton } from '../../../utils/style';
import { getStorageItem, setStorageItem } from '../../../utils/system';

const Wrap = styled('div')`
  width: 100%;
  height: 48px;
  position: relative;
  background-color: #283361;
  border-radius: ${border.borderRadius};

  ${flex};

  & > span {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
  }

  & .close {
    ${resetButton};
    width: 14px;
    height: 14px;

    ${flex};

    position: absolute;
    right: 17px;

    & > div {
      height: 2px;
      width: 140%;
      background-color: #2da5fe;
      border-radius: ${border.borderRadius};

      position: absolute;

      &:first-of-type {
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    height: max-content;
    padding: 15px 25px;

    & > span {
      font-size: 13px;
      line-height: 1.62;
      text-align: center;
    }

    & .close {
      width: 8px;
      height: 8px;
      top: 8px;
      right: 8px;
    }
  }
`;

const STORAGE_KEY = 'HOME_PREDICTION_HELP_TEXT_CLOSED';
const TopAlert = ({ text }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(!getStorageItem(STORAGE_KEY));
  }, []);

  const closeHandler = () => {
    setStorageItem(STORAGE_KEY, true);
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <Wrap>
          <span>{text}</span>
          <button type="button" name="Close" className="close" aria-label="Close Button" onClick={closeHandler}>
            <div />
            <div />
          </button>
        </Wrap>
      )}
    </>
  );
};

export default TopAlert;
